import React from "react";
import logo from "./logo.svg";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ListIssues from "./pages/Issues/ListIssues";
import AddIssues from "./pages/Issues/AddIssues";
import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n, t } = useTranslation();
  return (
    <div dir={i18n.dir()}>
      <Container className="py-3">
        <Form.Group className="pb-3">
          <Form.Label>{t("Language")}</Form.Label>
          <Form.Select
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
            size="lg"
          >
            <option value="ar">العربية</option>
            <option value="en">English</option>
          </Form.Select>
        </Form.Group>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/issues" element={<ListIssues />} />
          <Route path="/issues/add" element={<AddIssues />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
