import { Fetcher } from "swr";
import { Issue } from "../types/global";
export const apiUri = "/api";
export const linear_key = "lin_api_7zzUcWXBYOiNxrtOuR11o22R9HiIWvDEFuR9ysDT";
export const defaultFetchOptions = {
  headers: {
    Authorization: `Bearer ${linear_key}`,
  },
};
export const getIssues = async (teamKey: string) => {
  const issuesJson = await fetch(
    `${apiUri}/issues/${teamKey}`,
    defaultFetchOptions
  );
  console.log(issuesJson);
  const issues: Issue[] = await issuesJson.json();
  return issues;
};
export const fetcher = (url: string) => {
  return fetch(apiUri + url, defaultFetchOptions).then((r) => r.json());
};
