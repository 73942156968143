import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { getIssues } from "../../../utils/api";
import { Issue } from "../../../types/global";
import "./ListIssues.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getFormattedDate, Luminance } from "../../../utils";
import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const ListIssues = () => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState({
    group: "state",
    orderBy: "identifier",
  });
  const [loading, setLoading] = useState(false);
  const [issues, setIssues] = useState<Issue[]>([]);
  useEffect(() => {
    setLoading(true);
    getIssues("MOL").then((issues) => {
      setIssues(issues.sort((a, b) => b.number - a.number));
      setLoading(false);
    });
  }, []);
  const priorities = useMemo(() => {
    let prios: string[] = [];
    issues.forEach((issue) => {
      prios[issue.priority] = issue.priorityLabel;
    });
    return prios;
  }, [issues]);
  const states = useMemo(() => {
    let statesArray = issues.map((issue) => {
      return issue.state.name;
    });
    const uniq = [...new Set(statesArray)];
    return uniq;
  }, [issues]);
  const categorizedIssues = useMemo(() => {
    if (options.group === "priority") {
      return priorities.map((prio) => {
        return {
          name: prio,
          issues: issues.filter((issue) => issue.priorityLabel === prio),
        };
      });
    } else {
      return states.map((state) => {
        return {
          name: state,
          issues: issues.filter((issue) => issue.state.name === state),
        };
      });
    }
  }, [issues, priorities, options, states]);
  return (
    <Container className="py-5 border rounded-3">
      <Link to="/issues/add" className="w-100 btn btn-success mb-5">
        {t("Add Issue")}
      </Link>
      {/* <Form.Group className="pb-3">
        <Form.Label htmlFor="inputPassword5">Group by</Form.Label>
        <Form.Select
          onChange={(e) => {
            setOptions((prev) => {
              return { ...prev, group: e.target.value };
            });
          }}
          size="lg"
        >
          <option value="priority">Priority</option>
          <option value="state">State</option>
        </Form.Select>
      </Form.Group> */}
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("Priority")}</Th>
            <Th>{t("Title")}</Th>
            <Th>{t("Labels")}</Th>
            <Th>{t("State")}</Th>
            <Th>{t("Start Date")}</Th>
            <Th>{t("Due Date")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {categorizedIssues.map((issueCat) => {
            return (
              <>
                <Tr>
                  <Th>{t(issueCat.name)}</Th>
                </Tr>
                {issueCat.issues.map((issue) => {
                  return (
                    <Tr>
                      <Td>{issue.identifier}</Td>
                      <Td>{t(issue.priorityLabel)}</Td>
                      <Td>{issue.title}</Td>
                      <Td>
                        <div dir={i18n.dir()}>
                          {issue.labels.map((label) => {
                            return (
                              <Button
                                size="sm"
                                className="border-dark"
                                style={{
                                  backgroundColor: label.color,
                                  color: Luminance(label.color),
                                }}
                              >
                                {label.name}
                              </Button>
                            );
                          })}
                        </div>
                      </Td>
                      <Td>
                        <Button
                          size="sm"
                          className="border-dark"
                          style={{
                            backgroundColor: issue.state.color,
                            color: Luminance(issue.state.color),
                          }}
                        >
                          {t(issue.state.name)}
                        </Button>
                      </Td>
                      <Td>{getFormattedDate(issue.createdAt)}</Td>

                      <Td>{issue.dueDate}</Td>
                    </Tr>
                  );
                })}
              </>
            );
          })}
        </Tbody>
      </Table>
      {loading ? <h1>{t("Loading...")}</h1> : null}
    </Container>
  );
};

export default ListIssues;
