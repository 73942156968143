import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import useSWR from "swr";
import {
  apiUri,
  defaultFetchOptions,
  fetcher,
  linear_key,
} from "../../../utils/api";
import CheckMark from "../../../components/CheckMark";
import BoxIcon from "../../../components/BoxIcon";
import { Label, State } from "../../../types/global";
import { Luminance } from "../../../utils";
import fetch from "unfetch";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
const AddIssues = () => {
  const { t } = useTranslation();
  const { data: apiLabels } = useSWR<Label[]>("/labels/MOL", fetcher);
  const { data: apiStates } = useSWR<State[]>("/states/MOL", fetcher);
  const [labels, setLabels] = useState<Label[]>([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [state, setState] = useState("");
  const [priority, setPriority] = useState(0);

  const SubmitHandler = async () => {
    const result = await fetch(`${apiUri}/issues/MOL`, {
      headers: {
        Authorization: `Bearer ${linear_key}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        title: title,
        description: description,
        stateName: state,
        priority: priority,
        labels: labels,
      }),
    });
    console.log(await result.json());
  };
  return (
    <Container className="border rounded-3 py-3">
      <Link to="/issues" className="w-100 btn btn-success mb-5">
        {t("List Issue")}
      </Link>
      <Form className="px-3">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{t("Title")}</Form.Label>
          <Form.Control
            type="text"
            value={title}
            placeholder={t("Title")}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{t("Description")}</Form.Label>
          <Form.Control
            value={description}
            as="textarea"
            rows={3}
            placeholder={t("Description")}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="pb-3">
          <Form.Label htmlFor="inputPassword5">{t("State")}</Form.Label>
          <Form.Select
            onChange={(e) => {
              setState(e.target.value);
            }}
            size="lg"
          >
            {apiStates &&
              apiStates.map((state) => (
                <option value={state.name}>{t(state.name)}</option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="pb-3">
          <Form.Label htmlFor="inputPassword5">{t("Priority")}</Form.Label>
          <Form.Select
            onChange={(e) => {
              setPriority(parseInt(e.target.value));
            }}
            size="lg"
          >
            <option value={0}>{t("No priority")}</option>
            <option value={1}>{t("Urgent")}</option>
            <option value={2}>{t("High")}</option>
            <option value={3}>{t("Medium")}</option>
            <option value={4}>{t("Low")}</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
          <Form.Label>{t("Labels")}:</Form.Label>
          {apiLabels &&
            apiLabels.map((label) => {
              return (
                <Button
                  onClick={() => {
                    setLabels((prev) => {
                      let newLabels = [...prev];
                      if (prev.find((lbl) => lbl.name === label.name)) {
                        newLabels = newLabels.filter(
                          (lbl) => lbl.name !== label.name
                        );
                      } else {
                        newLabels = [...newLabels, label];
                      }
                      return newLabels;
                    });
                  }}
                  size="sm"
                  className="m-1"
                  style={{
                    backgroundColor: label.color,
                    color: Luminance(label.color),
                  }}
                >
                  {labels.find((lbl) => lbl.name === label.name) ? (
                    <CheckMark className="mx-1" />
                  ) : (
                    <BoxIcon className="mx-1" />
                  )}
                  {label.name}
                </Button>
              );
            })}
        </Form.Group>
        <Button className="w-100" onClick={SubmitHandler}>
          {t("Submit")}
        </Button>
      </Form>
    </Container>
  );
};

export default AddIssues;
